import auth from '@/middleware/auth.js';
import v1 from './routes/v1';
import v2 from './routes/v2';

let rutas = {
    path: '',
    component: () =>
        import ('@/layouts/Constelacion'),
    children: [
        {
            path: '/formalizacion',
            name: 'formalizacion',
            component: () =>
                import ('@/apps/formalizacion/pages/Dashboard'),
            meta: {
                title: 'Formalización',
                middleware: auth
            }
        }
    ]
};

rutas.children = rutas.children.concat(v1);
rutas.children = rutas.children.concat(v2);

export default rutas;