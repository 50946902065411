import auth from '@/middleware/auth.js'

export default [
  {
    path: '/formalizacion/v2/usuarios/solicitudes',
    name: 'formalizacion_v2_usuarios_solicitudes',
    component: () =>
        import ('@/apps/formalizacion/pages/V2/Usuarios/Solicitud'),
    meta: {
        title: 'Solicitudes - Formalización',
        middleware: auth
    }
  }
  ,{
    path: '/formalizacion/v2/notario/solicitudes/:solicitud'
    ,name: 'formalizacion_V2_usuarios_solicitudes_notario'
    ,component: () => import ('@/apps/formalizacion/pages/V2/Usuarios/Notario')
    ,meta: {
      title: 'Notario - Solicitudes - Formalización'
      ,middleware: auth
    }
  }
  ,{
    path: '/formalizacion/v2/valuador/solicitudes/:solicitud'
    ,name: 'formalizacion_V2_usuarios_solicitudes_valuador'
    ,component: () => import ('@/apps/formalizacion/pages/V2/Usuarios/Valuador')
    ,meta: {
      title: 'Valuador - Solicitudes - Formalización'
      ,middleware: auth
    }
  }
  ,{
    path: '/formalizacion/v2/administracion/solicitudes'
    ,name: 'formalizacion_V2_administracion_solicitudes'
    ,component: () => import ('@/apps/formalizacion/pages/V2/Administracion/Solicitud')
    ,meta: {
      title: 'Administración - Solicitudes - Formalización'
      ,middleware: auth
    }
  }
  ,{
    path: '/formalizacion/v2/administracion/solicitudes/:id'
    ,name: 'formalizacion_V2_administracion_solicitudes_detalle'
    ,component: () => import ('@/apps/formalizacion/pages/V2/Administracion/Detalle')
    ,meta: {
      title: 'Administración - Solicitudes - Formalización'
      ,middleware: auth
    }
  }
  ,{
    path: '/formalizacion/v2/administracion/etapas'
    ,name: 'formalizacion_V2_administracion_etapas'
    ,component: () => import ('@/apps/formalizacion/pages/V2/Administracion/Etapa')
    ,meta: {
      title: 'Administración - Solicitudes - Formalización'
      ,middleware: auth
    }
  }
  ,{
    path: '/formalizacion/v2/administracion/catalogos'
    ,name: 'formalizacion_V2_administracion_catalogos'
    ,component: () => import ('@/apps/formalizacion/pages/V2/Administracion/Catalogo')
    ,meta: {
      title: 'Administración - Solicitudes - Formalización'
      ,middleware: auth
    }
  }
  ,{
    path: '/formalizacion/v2/administracion/configuracion'
    ,name: 'formalizacion_V2_administracion_configuracion'
    ,component: () => import ('@/apps/formalizacion/pages/V2/Administracion/Configuracion')
    ,meta: {
      title: 'Administración - Solicitudes - Formalización'
      ,middleware: auth
    }
  }
]